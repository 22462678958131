scrollHeader();
toggleBurgerMenu();
toggleSubmenu();
toggleSubmenuDomn();
navigationStyling();
addHeaderMargin();
indexThirdLevelNavigation();
toggleDropdownImage();
scrollToSearch();

function addHeaderMargin() {
    const header = document.querySelector(".header");
    const headerHeight = header.offsetHeight;
    const breadcrumb = document?.getElementById("BreadCrumb");
    const elements = document?.getElementById("elements__startpage");

    if (breadcrumb) {
        breadcrumb.style.paddingTop = `${headerHeight}px`;
    } else {
        elements.style.paddingTop = `${headerHeight}px`;
    }
}

function toggleBurgerMenu() {
    const burger = document.querySelector(".respNav__burger");
    const menu = document.querySelector(".respNav__menu");

    if (!burger) return;

    burger.addEventListener("click", function () {
        if (menu.classList.contains("is__hidden")) {
            menu.classList.remove("is__hidden");
            burger.classList.add("respNav__burger--open");
        } else {
            menu.classList.add("is__hidden");
            burger.classList.remove("respNav__burger--open");
        }
    });
}

function toggleSubmenu() {
    const Menus = document.querySelectorAll(".respNav__item");

    Menus.forEach((menu) => {
        const itemm = menu.querySelector(".respNav__headline");
        const submenu = menu.querySelector(".respNav__subpages");

        itemm?.addEventListener("click", function () {
            submenu.classList.toggle("is__hidden");
        });
    });
}
function toggleSubmenuDomn() {
    const Menus = document.querySelectorAll(".respNav__item");

    Menus.forEach((menu) => {
        const itemm = menu.querySelectorAll(".respNav__down");
        itemm.forEach((itemdown, i) => {
            itemdown?.addEventListener("click", function () {
                const submenu = menu.querySelectorAll(".respNav__subpages");
                let d = Array.from(submenu);
                submenu[i + 1].classList.toggle("is__hidden");
            });
        });
    });
}

function scrollHeader() {
    const header = document.querySelector(".header");
    const siteNav = document.querySelector(".header__sitenavigation--container");
    const searchInput = document.querySelector(".search__input");

    if (!siteNav) return;

    document.addEventListener("scroll", function () {
        if (window.pageYOffset > 0) {
            header.classList.add("header__scroll");
            if (searchInput) {
                searchInput.classList.add("is__hidden");
            }
            if (window.innerWidth > 991) {
                siteNav.classList.add("is__hidden");
            }
        } else {
            if (header.classList.contains("header__scroll")) {
                header.classList.remove("header__scroll");
                siteNav.classList.remove("is__hidden");
                if (searchInput) {
                    searchInput.classList.remove("is__hidden");
                }
            }
        }
    });
}

function navigationStyling() {
    const Items = document.querySelectorAll(".navigation__container");
    const headerBackground = document.querySelector(".navigation__background");

    Items.forEach((item) => {
        if (item.querySelector(".navigation__dropdown")) {
            item.addEventListener("mouseover", function (e) {
                headerBackground.classList.remove("is__hidden");
            });

            item.addEventListener("mouseout", function (e) {
                headerBackground.classList.add("is__hidden");
            });
        }
    });
}

function indexThirdLevelNavigation() {
    const Containers = document.querySelectorAll(".navigation__container");

    Containers.forEach((container) => {
        const Links = container.querySelectorAll(".dropdown__item .navigation__link--third");
        const DropdownImgs = container.querySelectorAll(".pe__item-wrapper.navigation__level--third");
        var index = 100;

        Links.forEach((link) => {
            link.setAttribute("data-index", index);
            index++;
        });

        index = 100;

        DropdownImgs.forEach((image) => {
            image.setAttribute("data-index", index);
            index++;
        });
    });
}

function toggleDropdownImage() {
    const Containers = document.querySelectorAll(".navigation__container");

    Containers.forEach((container) => {
        const Links = container.querySelectorAll(".navigation__link");
        const DropdownImgs = container.querySelectorAll(".pe__item-wrapper");

        Links.forEach((link) => {
            link.addEventListener("mouseover", function () {
                const DropdownImgs = container.querySelectorAll(".pe__item-wrapper");
                const activeIndex = this.getAttribute("data-index");
                const activeItem = container.querySelector(`.pe__item-wrapper[data-index='${activeIndex}'`);

                DropdownImgs.forEach((item) => {
                    item.classList.remove("pe--active");
                });

                activeItem?.classList.add("pe--active");
            });

            link.addEventListener("mouseleave", function () {
                const firstItem = container.querySelector(".pe__item-wrapper[data-index='0']");

                DropdownImgs.forEach((item) => {
                    item.classList.remove("pe--active");
                });

                firstItem.classList.add("pe--active");
            });
        });
    });
}

function scrollToSearch() {
    const header_search = document.querySelector(".header__search");

    if (header_search) {
        const search_input = header_search.querySelector(".search__input");

        header_search.addEventListener("click", (event) => {
            if (search_input.classList.contains("is__hidden")) {
                event.preventDefault();
                window.scrollTo(0, 0);
            }
        });
    }
}
