import {
    toggleFilterCheckboxes,
    hideFilter,
} from "../../Helper/InspirationFilter";

(() => {
    const activeFilterWrapper = document.querySelector(".inspiration__filter--active-categories");

    const Checkboxes = document.querySelectorAll(".InspirationFilterCheckbox");

    const tippsFormResetFilter = document.querySelector("#tipps-form-reset-filter");
    tippsFormResetFilter?.addEventListener("click", function () {
        Checkboxes.forEach((checkbox) => {
            (checkbox as HTMLInputElement).checked = false;
        });
        RemoveFilterResetButtons();
    });

    const productFilterForm = document.getElementById("product-filter-form");
    if (productFilterForm) {
        const filterItems = productFilterForm?.querySelectorAll(".filter__input");
        filterItems?.forEach((item) => {
            item.addEventListener("change", () => {
                addFilterResetButton(item);
            });
        });
    }

    const tippsAndTricksFilterForm = document.getElementById("tipps-and-tricks-filter-form");
    if (tippsAndTricksFilterForm) {
        const filterItems = tippsAndTricksFilterForm?.querySelectorAll(".filter__input");
        filterItems?.forEach((item) => {
            item.addEventListener("change", () => {
                addFilterResetButton(item);
            });
        });
    }

    const customerReportFilterForm = document.getElementById("customer-report-filter-form");
    if (customerReportFilterForm) {
        const filterItems = customerReportFilterForm?.querySelectorAll(".filter__input");
        filterItems?.forEach((item) => {
            item.addEventListener("change", () => {
                addFilterResetButton(item);
            });
        });
    }

    const inspirationReportFilterForm = document.getElementById("usage-inspiration-filter-form");
    if (inspirationReportFilterForm) {
        const filterItems = inspirationReportFilterForm?.querySelectorAll(".filter__input");
        filterItems?.forEach((item) => {
            item.addEventListener("change", () => {
                addFilterResetButton(item);
            });
        });
    }

    function addFilterResetButton(item: Element) {
        // Check if item is checked
        if ((item as HTMLInputElement).checked) {
            const filterTextContent = item.parentElement?.querySelector(".filter__label")?.textContent;
            const filterName = item.getAttribute("name");
            const filterType = item.getAttribute("type");
            const filterCategory = item
                .closest(".inspiration__filter-wrapper")
                ?.querySelector(".inspiration__filter--header .inspiration__filter--title")
                ?.textContent?.trim();

            // Create Filter item and fill with content
            const filterItem = document.createElement("div");
            filterItem.classList.add("inspiration__filter--active-category");
            filterItem.innerHTML = `<span>${filterCategory}: ${filterTextContent}</span><svg width="20" height="20" viewBox="0 0 16 16" fill="currentColor"><path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/></svg>`;
            if (filterName) {
                filterItem.setAttribute("name", filterName);
            }

            // Remove filterItems of same name if filterType is radio
            if (filterType === "radio") {
                const filterItems = document.querySelectorAll(".inspiration__filter--active-category");

                filterItems.forEach((filterItem) => {
                    if (filterItem.getAttribute("name") === filterName) {
                        filterItem.remove();
                    }
                });
            }

            // Add filter to active filters
            activeFilterWrapper?.appendChild(filterItem);

            // Add event listener to remove filter
            filterItem.addEventListener("click", () => {
                (item as HTMLInputElement).checked = false;
                (item as HTMLInputElement).dispatchEvent(new Event("change", { bubbles: true }));
                filterItem.remove();
            });
        } else {
            // Remove filter from active filters
            const filterName = item.parentElement?.querySelector(".filter__label")?.textContent;
            const filterCategory = item
                .closest(".inspiration__filter-wrapper")
                ?.querySelector(".inspiration__filter--header .inspiration__filter--title")
                ?.textContent?.trim();

            const filterItems = document.querySelectorAll('.inspiration__filter--active-category');
            filterItems.forEach((filterItem) => {
                if (filterItem.textContent === `${filterCategory}: ${filterName}`) {
                    filterItem.remove();
                }
            });
        }
    }

    function RemoveFilterResetButtons() {
        const activeFilters = document.querySelectorAll(".inspiration__filter--active-category");
        activeFilters.forEach((filter) => {
            filter.remove();
        });
    }

    toggleFilterCheckboxes();
    hideFilter();

    // get distance between searchbar and top of page

    // scroll searchbar to top of viewport on searchbar change + 100px
    const searchBar = document.getElementById("search");
    searchBar?.addEventListener("input", () => {
        window.scrollTo({
            top: searchBar?.getBoundingClientRect().top + window.scrollY - 100,
            behavior: "smooth",
        });
    });
})();
