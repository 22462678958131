const filterReset = document.getElementById("customer-report-reset-filter");
if (filterReset) {
    filterReset.addEventListener("click", () => {
        const customerReportForm = document.getElementById("customer-report-filter-form");
        const filterItems = customerReportForm?.querySelectorAll(".inspiration__filter--active-category");
        filterItems?.forEach((filterItem) => {
            filterItem.remove();
        });
        customerReportForm?.reset();
    });
}
