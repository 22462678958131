export function toggleFilterCheckboxes() {
    const Filters = document.querySelectorAll(".inspiration__filter-wrapper");

    Filters.forEach((filter) => {
        const filterHeader = filter.querySelector(".inspiration__filter--header");
        const filterChecks = filter.querySelector(".inspiration__filter--items");

        filterHeader?.addEventListener("click", function () {
            document.querySelectorAll(".inspiration__filter--items").forEach((item) => {
                if (item !== filterChecks) {
                    item.classList.add("is__hidden");
                }
            });

            filterChecks?.classList.toggle("is__hidden");
        });
    });
}

export function hideFilter() {
    const hideButton = document.querySelector(".inspiration__filter--settings");
    const filter = document.querySelector(".inspiration__filter");
    const filterTexts = document.querySelectorAll(".inspiration__text");
    const filterActive = document.querySelector(".inspiration__filter--active-categories");

    hideButton?.addEventListener("click", function () {
        if (filter?.classList.contains("is__hidden")) {
            filter?.classList.remove("is__hidden");
            filterActive?.classList.remove("is__hidden");
        } else {
            filter?.classList.add("is__hidden");
            filterActive?.classList.add("is__hidden");
        }

        filterTexts.forEach((text) => {
            text.classList.toggle("d-none");
        });
    });
}
